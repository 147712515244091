<template>
  <div>
    <div class="d-md-flex my-3">
      <a-select
        size="large"
        :class="isMobile ? 'w-100' : 'mr-3'"
        style="min-width: 150px"
        @change="fetchDataSPprogress"
        v-model="viewBy"
      >
        <a-select-option
          value="all"
          >All</a-select-option
        >
        <a-select-option
          value="class"
          >By Class</a-select-option
        >
      </a-select>
      <template v-if="viewBy === 'class'">
        <a-select
          :class="isMobile ? ['w-100', 'my-3'] : 'mr-3'"
          size="large"
          placeholder="Select level"
          style="min-width: 150px"
          v-model="level"
          @change="changeSymbol"
        >
          <a-select-option
            v-for="(level, i) in levels"
            :value="level.id"
            :key="i"
            >{{ level.nama }}</a-select-option
          >
        </a-select>
        <a-select
          :class="isMobile ? 'w-100' : null"
          size="large"
          placeholder="Select symbol"
          style="min-width: 150px"
          v-model="symbol"
          @change="fetchDataSPprogress"
        >
          <a-select-option
            v-for="(symbol, i) in symbols"
            :value="symbol.id"
            :key="i"
            >{{ symbol.simbol }}</a-select-option
          >
        </a-select>
      </template>
    </div>
    <div v-if="viewBy === 'class'" class="d-md-flex justify-content-around my-5">
      <div class="d-flex flex-column align-items-center">
        <h2>E-mail</h2>
        <a-progress type="circle" :percent="emailCP" status="active" />
      </div>
      <div class="d-flex flex-column align-items-center">
        <h2>Phone</h2>
        <a-progress type="circle" :percent="phoneCP" status="active" />
      </div>
      <div class="d-flex flex-column align-items-center">
        <h2>Whatsapp</h2>
        <a-progress type="circle" :percent="whatsappCP" status="active" />
      </div>
    </div>
    <!-- <template>
      <a-divider>
        <span>
          <h2 :style="selectedClassId === '' ? 'color: red' : 'color: black'">
            {{ cascaderClassText }}
          </h2>
          <a-cascader :options="classOptions" @change="onChangeClass">
            <a>Change Class</a>
          </a-cascader>
        </span>
      </a-divider>
    </template> -->
    <a-row type="flex" align="middle" justify="center">
      <a-col :span="24">
        <a-table
          v-if="viewBy === 'class'"
          class="hasan-table-overflow"
          :columns="columnSPprogress"
          :data-source="dataTable"
          bordered
        >
          <div
            slot="Email"
            slot-scope="text, record"
            style="text-align: center; font-size: 1.5em"
          >
            <a-icon
              v-if="record.Email && record.Email !== ''"
              style="color: #41b883"
              type="check-circle"
            />
            <a-icon v-else style="color: #f70000" type="close-circle" />
          </div>
          <div
            slot="Phone"
            slot-scope="text, record"
            style="text-align: center; font-size: 1.5em"
          >
            <a-icon
              v-if="record.Phone && record.Phone !== ''"
              style="color: #41b883"
              type="check-circle"
            />
            <a-icon v-else style="color: #f70000" type="close-circle" />
          </div>
          <div
            slot="Whatsapp"
            slot-scope="text, record"
            style="text-align: center; font-size: 1.5em"
          >
            <a-icon
              v-if="record.Whatsapp && record.Whatsapp !== ''"
              style="color: #41b883"
              type="check-circle"
            />
            <a-icon v-else style="color: #f70000" type="close-circle" />
          </div>
        </a-table>
        <a-table
          v-else
          class="hasan-table-overflow"
          :columns="columnSPprogressAllClass"
          :data-source="dataSPallClass"
          bordered
        >
          <span slot="nullStr" slot-scope="text">{{text || '-'}}</span>
          <div class="mx-3" slot="emailPercentage" slot-scope="text">
            <a-progress type="line" :percent="Number(text.toFixed(1))" status="active" />
          </div>
          <div class="mx-3" slot="phoneWAPercentage" slot-scope="text">
            <a-progress type="line" :percent="Number(text.toFixed(1))" status="active" />
          </div>
        </a-table>
        <!-- <div v-else class="d-flex align-items-center flex-wrap">
                          <a-card v-for="item in dataSPallClass" :key="item.classId" :title="item.className +', ' + item.homeroomTeacher"     class="mt-3 ml-2" style="width: 300px">
                            <div class="d-flex justify-content-between">
                              <i class="far fa-envelope"/>
                                {{item.emailProgress}} / {{item.totalStudent}}
                              <div style="width: 150px">
                                <a-progress
                                  type="line"
                                  :show-info="true"
                                  :percent="item.emailPercentage"
                                  :strokeWidth="12"
                                  strokeColor="#40B983"
                                />
                              </div>
                            </div>
                            <div class="d-flex justify-content-between">
                              <i class="fas fa-mobile-alt"/>
                              {{item.phoneWAProgress}} / {{item.totalStudent}}
                              <div style="width: 150px">
                                <a-progress
                                  type="line"
                                  :show-info="true"
                                  :percent="item.phoneWAPercentage"
                                  :strokeWidth="12"
                                  strokeColor="#40B983"
                                />
                              </div>
                            </div>
                          </a-card>
                        </div> -->
      </a-col>
    </a-row>
  </div>
</template>

<script>
const columnSPprogress = [
  {
    title: 'No',
    dataIndex: 'No',
    width: 60,
    align: 'center',
    scopedSlots: { customRender: 'No' },
  },
  {
    title: 'Name',
    dataIndex: 'Name',
    width: 360,
    scopedSlots: { customRender: 'Name' },
  },
  {
    title: "Parent's Input Data Progress",
    children: [
      {
        title: 'Email',
        dataIndex: 'Email',
        width: 120,
        align: 'center',
        scopedSlots: { customRender: 'Email' },
      },
      {
        title: 'Phone',
        dataIndex: 'Phone',
        width: 120,
        align: 'center',
        scopedSlots: { customRender: 'Phone' },
      },
      {
        title: 'Whatsapp',
        dataIndex: 'Whatsapp',
        width: 120,
        align: 'center',
        scopedSlots: { customRender: 'Whatsapp' },
      },
    ],
  },
]
const columnSPprogressAllClass = [
  {
    title: 'Class',
    dataIndex: 'className',
    align: 'center',
    width: 100,
    scopedSlots: { customRender: 'className' },
  },
  {
    title: 'Homeroom Teacher',
    dataIndex: 'homeroomTeacher',
    width: 200,
    scopedSlots: { customRender: 'nullStr' },
  },
  {
    title: 'Email Inputted',
    dataIndex: 'emailProgress',
    width: 100,
    align: 'center',
    scopedSlots: { customRender: 'emailProgress' },
  },
  {
    title: 'Phone / WA Inputted',
    dataIndex: 'phoneWAProgress',
    width: 120,
    align: 'center',
    scopedSlots: { customRender: 'phoneWAProgress' },
  },
  {
    title: 'Total Student',
    dataIndex: 'totalStudent',
    width: 100,
    align: 'center',
    scopedSlots: { customRender: 'totalStudent' },
  },
  {
    title: "Parent's Input Data Progress",
    children: [
      {
        title: 'Email Progress',
        dataIndex: 'emailPercentage',
        align: 'center',
        width: 250,
        scopedSlots: { customRender: 'emailPercentage' },
      },
      {
        title: 'Phone / WA Progress',
        dataIndex: 'phoneWAPercentage',
        align: 'center',
        width: 250,
        scopedSlots: { customRender: 'phoneWAPercentage' },
      },
    ],
  },
]

export default {
  data() {
    return {
      selectedClassId: '',
      columnSPprogress,
      columnSPprogressAllClass,
      loadingTable: false,
      savedData: [],
      dataTable: [],
      startValue: null,
      endValue: null,
      cascaderClassText: 'All Class',
      loadingDelete: false,
      idEdit: null,
      classOptions: [],
      dataSPallClass: [],
      emailCP: 0,
      phoneCP: 0,
      whatsappCP: 0,
      levels: [],
      level: null,
      symbols: [],
      symbol: null,
      viewBy: 'all',
    }
  },
  methods: {
    async fetchDataClass() {
      const res = await this.$store.dispatch('curriculum/FETCH_CLASS')
      console.log('fetchDataClass =>', res)
      const classes = res.classes
      const levels = res.levels
      const optionsData = levels.map((row) => {
        const children = []
        classes.forEach((el) => {
          const classLevel = el.tingkat
          const classId = el.id
          const classSymbol = el.simbol
          const childObj = {
            label: classSymbol,
            value: classId,
          }
          if (row.tingkat === classLevel) {
            children.push(childObj)
          }
        })
        return {
          value: row.tingkat,
          label: row.tingkat,
          children,
        }
      })
      const optionsObj = {
        label: 'All',
        value: 'All',
        children: [
          {
            label: 'Class',
            value: '',
          },
        ],
      }
      optionsData.push(optionsObj)
      // console.log(optionsData)
      this.classOptions = optionsData
    },
    fetchDataSPprogress() {
      this.loadingTable = true
      const payload = this.viewBy === 'all' ? {} : { idKelas: this.symbol }
      this.$store
        .dispatch('curriculum/FETCH_STUDENT_PARENT_INPUT_DATA_PROGRESS', payload)
        .then((res) => {
          const message = res.message
          const data = res.data
          const percentage = res.percentage
          if (message === 'SP_BY_CLASS_ID') {
            let no = 1
            this.dataTable = data.map((row) => {
              return {
                key: row.id,
                No: no++,
                Name: row.nama,
                Email: row.emailStatus,
                Phone: row.phoneStatus,
                Whatsapp: row.whatsappStatus,
              }
            })
            this.loadingTable = false
            this.emailCP = percentage.emailCP
            this.phoneCP = percentage.phoneCP
            this.whatsappCP = percentage.whatsappCP
          } else {
            this.dataSPallClass = data
          }
        })
    },
    onChangeClass(value, selectedClassOptions) {
      this.selectedClassId = value[0] === 'All' ? null : value[0]
      this.cascaderClassText = selectedClassOptions
        .map((o) => o.label)
        .join(' - ')
      this.fetchDataSPprogress()
    },
    save() {
      this.modalVisible = true
    },
    async fetchLevels() {
      try {
        this.levels = await this.$store.dispatch('master/FETCH_LEVELS_AND_CLASSES', { idMapel: null })
        if (this.levels.length) {
          this.level = this.levels[0].id
          this.symbols = this.levels[0].kelas
          this.symbol = this.symbols[0].id
        }
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    changeSymbol(value) {
      this.symbols = this.levels.find(level => level.id === value)?.kelas
      if (this.symbols.length) {
        this.symbol = this.symbols[0].id
        this.fetchDataSPprogress()
      }
    },
  },
  mounted() {
    this.fetchLevels()
    this.fetchDataSPprogress()
    // this.fetchDataClass()
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
}
</script>
